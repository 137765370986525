import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ProgressProviderProps {
  children: ReactNode; // Ensure `children` is recognized as a prop
}

const ProgressContext = createContext<{
  isDataFetched: boolean;
  setDataFetched: (value: boolean) => void;
} | undefined>(undefined);

export const ProgressProvider: React.FC<ProgressProviderProps> = ({ children }) => {
  const [isDataFetched, setDataFetched] = useState(false);

  return (
    <ProgressContext.Provider value={{ isDataFetched, setDataFetched }}>
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => {
  const context = useContext(ProgressContext);
  if (!context) throw new Error("useProgress must be used within a ProgressProvider");
  return context;
};
