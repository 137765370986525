import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { styled } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import type { PageLayout } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useLocation } from "react-router-dom";
import { addUserForm } from "../../store/thunk/formThunk";
import { notificationFail } from "../../store/slices/notificationSlice";
import SignInSignUpButton from "../payAndDownload/signInSignUpButton";
import ValidateFormJson from "../../components/stepper/validateFormJson";
import {
  checkDependantQuestion,
  getApiUrl,
  anonymousUser,
  setAppInsightsData,
} from "../../helper/service";
import { generateGuid } from "../../helper/generateGuid";
import NoPdf from "../../assets/images/NoPdf.svg";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(0, 1, 0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

let pdfformselected = "";

export default function PreviewPDF(props: any) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  let { togglePreviewDrawer, hidden } = props;

  const currentQuestionList = useAppSelector(
    (state: any) => state.formReducer.currentQuestionList
  );
  const { authConfig } = useAppSelector((state: any) => state.authReducer);
  const { uiFormPayload } = useAppSelector((state: any) => state?.formReducer);

  let localCaseTypeData: any = localStorage.getItem("caseTypeDetails");
  const caseTypeDetails: any = JSON.parse(localCaseTypeData);

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  useEffect(() => {
    const matchingFormData = bootstrapData?.formsForCaseType?.find(
      (form: any) => form.caseId === caseTypeDetails.caseId
    );

    // Append formNames if a match is found
    if (matchingFormData) {
      caseTypeDetails.formNames = matchingFormData.formNames;
      caseTypeDetails.isStandalone = matchingFormData.isStandalone;
    }

    // Save the updated caseTypeDetails back to localStorage
    localStorage.setItem("caseTypeDetails", JSON.stringify(caseTypeDetails));
  }, [caseTypeDetails]);

  const [value, setValue] = useState<any>();
  const [formName, setFormName] = useState<string>("");
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [locked, setLocked] = useState<any>(false);
  const [isInIframe, setIsInIframe] = useState(false);

  const pdfBaseUrl =
    "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

  const pageLayout: PageLayout = {};
  const thumbnailPluginInstance = thumbnailPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.top !== window.self) {
      setIsInIframe(true);
    }
  }, [location]);

  useEffect(() => {
    if (uiFormPayload?.ui_form_lock_state) {
      setLocked(uiFormPayload?.ui_form_lock_state);
      localStorage.setItem("lockedApp", uiFormPayload?.ui_form_lock_state);
    } else {
      setLocked(false);
      localStorage.setItem("lockedApp", "false");
    }
  }, [uiFormPayload]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
    key: string
  ) => {
    const buttonElement = event.target as HTMLElement;

    // setAppInsightsData(key || "", authConfig);
    setFormName(key || "");
    setValue(newValue);
  };

  const [pdfUrlList, setPDFUrlList] = useState<any>([
    // "i-864",
    // "i-130",
    // "i-130a",
    // "i-131",
    // "i-485",
    // "i-765",
    // "g-1145",
    // "i-693",
  ]);

  useEffect(() => {
    if (caseTypeDetails?.length > 0) {
      setPDFUrlList(caseTypeDetails[0]?.formNames);
    }
  }, []);

  useEffect(() => {
    const formValuesForPDF: any = sessionStorage.getItem("unSavedFormValues");
    const tempFormValues = JSON.parse(formValuesForPDF);
    let updatedList: any = []; //"i-485"

    const tempCaseTypeFormName = [...caseTypeDetails?.formNames];

    if (!caseTypeDetails?.isStandalone) {
      updatedList.push("i-485");
      if (tempFormValues?.GTKY24 === "N") {
        updatedList.push("i-130", "i-130a");
      }
      if (tempFormValues?.I864Wflag === "N") {
        updatedList.push("i-864");
      }
      if (
        tempFormValues?.GTKY25?.includes("AllYes") ||
        tempFormValues?.GTKY25?.includes("I765Yes")
      ) {
        updatedList.push("i-765");
      }
      if (
        tempFormValues?.GTKY25?.includes("AllYes") ||
        tempFormValues?.GTKY25?.includes("I131Yes")
      ) {
        updatedList.push("i-131");
      }
      if (
        tempFormValues?.GTKY25?.includes("AllYes") ||
        tempFormValues?.GTKY25?.includes("G1145Yes") ||
        anonymousUser()
      ) {
        updatedList.push("g-1145");
      }
      if (tempFormValues?.MED1031 === "N") {
        updatedList.push("i-693");
      }
      if (
        tempFormValues?.I864Wflag === "N" &&
        tempFormValues?.income_limit_flag > 4 &&
        tempFormValues?.income_limit_flag < 7
      ) {
        updatedList.push("i-864a-household-member-1");
      }
      if (
        tempFormValues?.I864Wflag === "N" &&
        tempFormValues?.income_limit_flag > 6
      ) {
        updatedList.push("i-864-joint-sponsor");
      }
      if (
        tempFormValues?.I864Wflag === "N" &&
        tempFormValues?.income_limit_flag > 8
      ) {
        updatedList.push("i-864a-household-member-2");
      }
      if (tempFormValues?.Q1Headers === "Y") {
        updatedList.push("g-28-immigrant", "g-28-petitioner");
      }
      if (tempFormValues?.I864Wflag === "Y") {
        updatedList = updatedList.filter((item: string) => item !== "i-864");
        updatedList.push("i-864w");
      }

      /* 
      TODO : The following lines have been commented out because
      they break the PDF preview when we are dealing with a full Marriage 
      AOS case type
      if (tempFormValues?.Q1Relationshipsponsor > 0) {
        updatedList.push("i-864a");
      }*/

      updatedList = Array.from(new Set(updatedList));

      setPDFUrlList([...updatedList]);
    } else {
      setPDFUrlList(tempCaseTypeFormName);
    }
    if (updatedList.length > value) {
      setFormName(updatedList[value]);
    } else if (tempCaseTypeFormName.length > value) {
      setFormName(tempCaseTypeFormName[value]);
    } else {
      setFormName("");
      setPdfUrl("");
      setValue(updatedList.length);
    }

    if (anonymousUser()) {
      const indexx = updatedList.findIndex((val: any) => val == "g-1145");
      setFormName("g-1145");
      setValue(indexx);
    }
  }, [sessionStorage.getItem("unSavedFormValues")]);

  const loadData = () => {
    const baseUrl = window.API_URL;
    const usapathEmulateUserId = window.sessionStorage.getItem(
      "Usapath-Emulate-UserId"
    );
    // **** if requirement is to use different session ID for all the tabs  ****
    let sessionID = sessionStorage.getItem("Usapath-Session-Id");
    if (!sessionID) {
      sessionID = generateGuid();
      sessionStorage.setItem("Usapath-Session-Id", sessionID);
    }

    const headers: any = {};
    if (usapathEmulateUserId) {
      headers["Usapath-Emulate-UserId"] = usapathEmulateUserId;
    }

    // AnonymousUser can access form G-1145 only
    headers["Usapath-Request-Id"] = generateGuid();
    headers["Usapath-Session-Id"] = sessionID;
    if (
      // windowWidth > 767 &&
      !anonymousUser() ||
      (anonymousUser() && formName === "g-1145")
    ) {
      setLoading(true);
      let formData: any = sessionStorage.getItem("unSavedFormValues") || "{}";

      const requestOptions = {
        method: anonymousUser() ? "POST" : "GET",
        headers: {
          ...headers,
          ...(anonymousUser()
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "accessToken"
                )}`,
              }), // Include headers only if not anonymous
        },
        body: anonymousUser() ? formData : null,
      };

      fetch(
        `${baseUrl}/PdfPreview/${
          anonymousUser() && formName === "g-1145" ? "g1145" : formName
        }`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => {
          if (pdfformselected === formName) {
            // Create a URL for the blob data
            const pdfBlob = new Blob([blob], {
              type: "application/pdf", // Old Code: type: "application/pdf" || "application/x-pdf"
            });
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(`${url}#toolbar=0&navpanes=0`);
          }
        })
        .catch((error) => {
          dispatch(
            notificationFail(
              "We have detected an error. Please contact support for assistance."
            )
          );
        })
        .finally(() => setLoading(false));
    } else {
      setPdfUrl("");
    }
  };

  const debouncedLoadData = (refresh = false) => {
    pdfformselected = formName;
    if (
      pdfformselected === formName &&
      pdfformselected !== "" &&
      formName !== ""
    ) {
      if (refresh) {
        loadData();
      } else {
        setTimeout(() => {
          loadData();
        }, 500);
      }
    }
  };

  useEffect(() => {
    debouncedLoadData();
  }, [formName]);

  const refreshData = () => {
    saveUpdatedField();
  };

  const saveUpdatedField = () => {
    let queList = [...currentQuestionList];
    const unSavedFormValues = JSON.parse(
      sessionStorage.getItem("unSavedFormValues") || "{}"
    );
    const updatedFormValues = JSON.parse(
      sessionStorage.getItem("updatedFormValues") || "{}"
    );

    let list = queList.filter((val: any) =>
      checkDependantQuestion(val, unSavedFormValues)
    );
    let err = ValidateFormJson({ formData: unSavedFormValues, list });

    const filteredObj2 = Object.keys(updatedFormValues).reduce(
      (acc: any, key: any) => {
        if (!err.hasOwnProperty(key)) {
          acc[key] = updatedFormValues[key];
        }
        return acc;
      },
      {}
    );
    dispatch(
      addUserForm({
        form_id: 1073,
        form_data: JSON.stringify(filteredObj2),
      })
    )
      .then((res: any) => {
        debouncedLoadData(true);
      })
      .catch((err: any) => {
        debouncedLoadData(true);
      });
  };

  const tabColors = [
    "#d0fffe",
    "#fffddb",
    "#e4ffde",
    "#ffd3fd",
    "#ffe7d3",
    "#d0fffe",
    "#fffddb",
    "#e4ffde",
    "#d0fffe",
    "#fffddb",
    "#e4ffde",
    "#ffe7d3",
    "#d0fffe",
    "#fffddb",
    "#e4ffde",
    "#d0fffe",
    "#fffddb",
    "#e4ffde",
  ];

  const formLabels: { [key: string]: string } = {
    "i-864a-household-member-1": "I864a Household Member of Petitioner",
    "i-864a-household-member-2": "I864a Household Member of Joint Sponsor",
    "i-864-joint-sponsor": "I864 for Joint Sponsor",
    "g-28-immigrant": "G28 for Immigrant",
    "g-28-petitioner": "G28 for Petitioner",
  };

  return (
    <Box sx={{ height: !hidden ? "calc(100% - 30px)" : "100%" }}>
      <DrawerHeader
        className="drawer-header drawerHeaderPreview"
        sx={{ justifyContent: "center", p: "0 0" }}
      >
        <div
          className=" w-full flex justify-between py-2"
          style={{ padding: "8px" }}
        >
          <span className="Sen-Bold self-center text-md font-extrabold whitespace-nowrap light:text-white text-[#0C1932]">
            Form Preview
          </span>
          <div style={{ display: "flex" }}>
            <Tooltip title="Refresh" arrow>
              <Button
                // loading={false}
                type="button"
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                placeholder="Test"
                onClick={refreshData}
                size="sm"
                color="blue"
                variant="outlined"
              >
                <RefreshIcon
                  sx={{ color: "black", fontSize: "14px", marginRight: "2px" }}
                />
                Refresh
              </Button>
            </Tooltip>
            <Tooltip title="close" arrow>
              <IconButton onClick={togglePreviewDrawer}>
                <CloseIcon sx={{ color: "black", fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            flexGrow: 1,
            maxWidth: { xs: 320, sm: 480 },
          }}
        >
          <Tabs
            value={value}
            onChange={(event, newValue) => handleChange(event, newValue, "")}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {pdfUrlList &&
              pdfUrlList?.map((obj: any, ind: any) => (
                // !isInIframe ?
                //   !["i-864a-household-member-1", "i-864a-household-member-2", "i-864-joint-sponsor", "i-864w", "g-28-petitioner", "g-28-immigrant"].includes(obj) &&
                //   <Tab
                //     label={obj}
                //     sx={{
                //       color: "black",
                //       padding: "0px",
                //       backgroundColor: tabColors[ind],
                //     }}
                //     onClick={(event) => handleChange(event, ind, obj)}
                //   /> :
                <Tab
                  label={formLabels[obj] || obj}
                  sx={{
                    color: "black",
                    padding: "0px",
                    backgroundColor: tabColors[ind],
                  }}
                  onClick={(event) => handleChange(event, ind, obj)}
                />
              ))}
          </Tabs>
        </Box>
      </DrawerHeader>
      <Divider />
      {loading ? (
        <Box
          sx={{
            height: "calc(100% - 100px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {windowWidth < 767 && anonymousUser() ? (
            <div
              style={{
                height: "calc(100% - 70px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SignInSignUpButton />
            </div>
          ) : (
            <>
              {anonymousUser() && formName !== "g-1145" ? (
                <div
                  style={{
                    height: "calc(100% - 100px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SignInSignUpButton />
                </div>
              ) : (
                <>
                  {pdfUrl ? (
                    <div
                      style={{
                        height: "calc(100% - 100px)",
                      }}
                    >
                      <Worker workerUrl={pdfBaseUrl}>
                        <Viewer
                          fileUrl={pdfUrl}
                          pageLayout={pageLayout}
                          plugins={[
                            pageNavigationPluginInstance,
                            thumbnailPluginInstance,
                          ]}
                          initialPage={0}
                          httpHeaders={{
                            mode: "no-cors",
                          }}
                          defaultScale={SpecialZoomLevel.PageWidth}
                        />
                      </Worker>
                    </div>
                  ) : (
                    <div className="noPdfText">
                      <img src={NoPdf} alt="noPdfText" />
                      <p>
                        Please select one of the forms above to see the preview
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
